// Variables

$primary-main-color: #000;
$grey-1-color: #c4c4c4;
$grey-2-color: #525252;
$grey-3-color: #3a3a3a;
$background-color: #eeeeee;
$primary-accent-color: #1565c0;
$secondary-accent-color: #e5e5e5;
$margin-small: 4px;
$margin-medium: 8px;
$margin-large: 16px;
$margin-xlarge: 24px;
$margin-xxlarge: 36px;

// Mixins

@mixin primary-button {
  font-size: 18px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

@mixin outlined-app-button {
  border: 2px solid !important;
  font-size: 18px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

// App level components

.AppContainer {
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: #212121;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  background-color: $background-color;
  overflow-y: auto;
}

.App > *:first-child {
  flex-grow: 1;
  padding: $margin-xlarge;
}

@media (min-width: 576px) {
  .App {
    max-height: 780px;
  }
}

// Components

.PrimaryButton {
  @include primary-button;
}

.PrimaryLinkButton {
  @include primary-button;
}

.OutlinedButton {
  @include outlined-app-button;
}

.OutlinedLinkButton {
  @include outlined-app-button;
}

.Toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Toolbar__left {
  margin-right: $margin-large;
  min-width: 90px;
}

.Toolbar__center {
  flex-grow: 1;
}

.Toolbar__right {
  margin-left: $margin-large;
}

.ToolbarButton {
  .ToolbarButton__button {
    border: 2px solid;
    font-family: 'Rajdhani', sans-serif;
    font-size: 18px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.GappedHorizontalContainer > *:not(:last-child) {
  margin-right: $margin-large;
}

.List {
  list-style: none;
  padding: 0;

  .ListItem {
    width: 100%;
  }
}

.List > *:not(:last-child) {
  margin-bottom: $margin-xlarge;
}

.AuthLink {
  text-align: center;

  .AuthLink__prefix {
    margin-right: 4px;
  }

  .AuthLink__link {
    .AuthLink__link__a {
      text-decoration: none;
      color: $primary-accent-color;
      transition: all 0.2s;
    }

    .AuthLink__link__a:hover,
    .AuthLink__link__a:active {
      color: $primary-main-color;
    }
  }
}

.LabeledText {
  .LabeledText__label {
    text-transform: uppercase;
    color: $primary-main-color;
    font-weight: 900;
  }
}

.LabeledTextLink {
  .LabeledTextLink__label {
    text-transform: uppercase;
    color: $primary-main-color;
    font-weight: 900;
  }

  .LabeledTextLink__url {
    text-decoration: none;
    color: $primary-accent-color;
    transition: all 0.2s;
  }

  .LabeledTextLink__url:hover,
  .LabeledTextLink__url:active {
    color: $primary-main-color;
  }
}

.SocialProfileItem {
  display: flex;
  flex-direction: row;
  align-items: center;

  .SocialProfileItem__left {
    flex-grow: 1;
  }

  .SocialProfileItem__name {
    font-size: 24px;
    margin: 0;
    text-transform: uppercase;
    color: $primary-main-color;
  }

  .SocialProfileItem__url {
    font-size: 12px;
    margin: 0;
    text-transform: none;
  }
}

.SocialProfileEditDialog__inputs {
  display: flex;
  flex-direction: column;
}

.SocialProfileEditDialog__inputs > *:not(:last-child) {
  margin-bottom: $margin-large;
}

.SocialProfileEditDialog__actions {
  .SocialProfileEditDialog__button {
    font-family: 'Rajdhani', sans-serif;
    font-size: 18px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.LargeAvatar {
  .LargeAvatar__image {
    border-radius: 8px;
    width: 100%;
    height: auto;
  }

  .LargeAvatar__edit {
    color: $background-color;
    background-color: $primary-main-color;
    border-radius: 50%;
    border: 2px solid;
    padding: 2px;
  }

  .LargeAvatar__progress {
    width: 24px;
    height: 24px;
    color: $background-color;
    background-color: $primary-main-color;
    border-radius: 50%;
    border: 2px solid;
    padding: 2px;
  }
}

.TextLogo {
  display: flex;

  .TextLogo__svg {
    height: 28px;
  }
}

.WhiteTextLogo {
  display: flex;

  .WhiteTextLogo__svg {
    height: 16px;
  }
}

.IconLogo {
  display: flex;
  justify-content: center;

  .IconLogo__svg {
    height: 72px;
  }
}

.ContactListItem {
  display: flex;
  align-items: center;

  .ContactListItem__text {
    flex-grow: 1;
  }
}

.Footer {
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px 16px;
  background-color: $primary-main-color;

  .WhiteTextLogo {
    justify-content: center;
    padding: 16px;
  }

  .Footer__first_line {
    display: flex;
    justify-content: center;
    color: $background-color;
  }

  .Footer__second_line {
    .FooterLink {
      .FooterLink__link {
        .FooterLink__link__a {
          font-weight: 700;
        }
      }
    }
  }

  .FooterLink {
    margin: 0px 8px;
  }
}

.FooterLink {
  text-align: center;

  .FooterLink__prefix {
    color: $background-color;
  }

  .FooterLink__link {
    .FooterLink__link__a {
      text-decoration: none;
      color: $background-color;
      transition: all 0.2s;
    }

    .FooterLink__link__a:hover,
    .FooterLink__link__a:active {
      color: $secondary-accent-color;
    }
  }
}

.ContactMenu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.VerticalSeparator {
  border-left: 2px solid $primary-main-color;
  margin: 0 8px;
  height: 32px;
}

.HorizontalSeparator {
  border-top: 2px solid $primary-main-color;
  width: 100%;
}

// Views

.View {
  .View__details {
    margin-top: $margin-xxlarge;
  }
}

.AuthView {
  .AuthView__details {
    margin-top: 30%;

    .TextLogo {
      justify-content: center;
      margin-bottom: 64px;

      .TextLogo__svg {
        height: 36px;
      }
    }

    .LabeledTextField {
      margin-bottom: $margin-medium;
    }

    .PrimaryButton {
      width: 50%;
      margin-top: $margin-small;
    }

    .AuthView__navigation {
      margin-top: $margin-xxlarge;
    }
  }
}

.FullscreenDialogView {
  width: 100%;
  height: 100%;

  .FullscreenDialogView__details {
    padding-top: 50%;

    .FullscreenDialogView__title {
      text-align: center;
    }

    .FullscreenDialogView__message {
      margin-top: $margin-xlarge;
    }

    .FullscreenDialogView__control {
      display: flex;
      margin-top: $margin-xxlarge;
    }
  }
}

.App {
  .TagView {
    padding: 0;
  }
}

.TagView {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .TagView__top_segment {
    background-color: $background-color;
    padding: $margin-xlarge;

    .TagView__header {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .TagView__header_avatar {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
      }

      .TagView__header_menu {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        justify-self: end;
      }

      .TagView__header_company {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
      }

      .TagView__header_company_logo {
        height: 96px;
      }

      .TagView__menu_icon {
        width: 33px;
        height: 33px;
        color: $primary-main-color;
      }
    }

    .TagView__name {
      margin-top: $margin-xlarge;
    }

    .TagView__short_description {
      margin-top: $margin-medium;
      display: -webkit-box;
      overflow: hidden;
      white-space: pre-wrap;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .ContactMenu {
      margin-top: $margin-xlarge;
    }
  }

  .TagView__bottom_segment {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: $margin-xlarge;
  }
}

.AboutView {
  .AboutView__description {
    margin-top: $margin-large;
    white-space: pre-wrap;
  }
}

.EditView__inputs {
  margin-top: $margin-xxlarge;
}

.EditView__inputs > *:not(:last-child) {
  margin-bottom: $margin-large;
}

.ContactView__details {
  margin-top: $margin-xxlarge;
  padding-left: 36px;
  padding-right: 36px;
}

.ContactView__details > *:not(:last-child) {
  margin-bottom: $margin-large;
}

.SocialView {
  .SocialView__button {
    width: 100%;
    display: block;
    text-align: left;
    @include outlined-app-button;
  }
}

.SocialView__details > *:not(:last-child) {
  margin-bottom: $margin-large;
}

.RootView {
  display: flex;
  flex-direction: column;

  .RootView__menu_icon {
    width: 33px;
    height: 33px;
    color: $primary-main-color;
  }

  .RootView__details {
    padding-top: 30%;
    text-align: center;

    .TextLogo {
      justify-content: center;
      margin-bottom: 20%;

      .TextLogo__svg {
        height: 36px;
      }
    }
  }

  .RootView__or {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $grey-1-color;
  }

  .RootView__or:after,
  .RootView__or:before {
    content: '';
    display: block;
    background: $grey-1-color;
    width: 30%;
    height: 1px;
    margin: 0 10px;
  }

  .MyProfileLink__link {
    margin-left: 4px;

    .MyProfileLink__link__a {
      text-decoration: none;
      color: $primary-accent-color;
      transition: all 0.2s;
    }

    .MyProfileLink__link__a:hover,
    .MyProfileLink__link__a:active {
      color: $primary-main-color;
    }
  }
}

.OnboardingView {
  height: 100%;

  .OnboardingView__details {
    height: 100%;
    display: flex;
    flex-direction: column;

    .MuiStepper-root {
      background-color: $background-color;
      padding: 0;
    }

    .OnboardingView__contents {
      flex-grow: 1;
      margin: $margin-large 0;
      overflow-y: auto;

      .EditView__inputs {
        margin-top: $margin-xxlarge;
      }

      .SocialEditView {
        .SocialView__button {
          width: 100%;
          display: block;
          text-align: left;
          @include outlined-app-button;
        }
      }
    }

    .OnboardingView__controls {
      display: flex;
    }
  }
}

.Form {
  margin-top: $margin-large;
}

.Form > *:not(:last-child) {
  margin-bottom: $margin-large;
}
